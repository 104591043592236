import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const PageComponent = ({ data }) => {
    const { strapiMediaPages: pageData } = data
    const __html = pageData.fields?.markdownBody?.childMarkdownRemark.html
    const backgroundImage =
        pageData.BackgroundImage?.localFile?.childImageSharp.gatsbyImageData
    return (
        <Layout backgroundImage={backgroundImage}>
            <Seo title={pageData.Title} />
            <div className='md' dangerouslySetInnerHTML={{ __html }} />
        </Layout>
    )
}

export default PageComponent
export const pageQuery = graphql`
    query MediaPages($id: String!) {
        strapiMediaPages(id: { eq: $id }) {
            Title
            BackgroundImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            fields {
                markdownBody {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`
